import React, { useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect";
import { getJson, getJsonObject } from "../config/config";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import Slider from "react-slick";
const getClinicDates = () => {
  //TODO: Write code for extracting dates from a json file
  //* write the data extraction here
  const dates = getJson("clinicDates");

  let datastr = dates.join(", ");
  return datastr;
};

const Home = ({
  classicHeader,
  darkTheme,
  handleNavClick,
  futureClinicDates,
}) => {
  const bgImages = getJsonObject("backgroundImagesPath");
  const numberOfImages = bgImages.length;
  const [bgImagesPath, setBgImagesPath] = useState("");
  const [bgText, setBgText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const startSlideShow = async () => {
    // Start slideshow
    while (isVisible) {
      setInterval(() => {
        if (numberOfImages - 1 == currentIndex) {
          setCurrentIndex(0);
        }
        setBgImagesPath(bgImages[currentIndex]);
        console.log(currentIndex + "Path of the image check");
        setCurrentIndex(currentIndex + 1);
      }, 2000); // Change image every 5 seconds

      //setSlideshowInterval(interval);
    }
  };

  useEffect(() => {
    const handleClick = () => {
      let componentHeight = document.querySelector("#home").clientHeight;
      let scrolled = window.scrollY;

      if (scrolled >= componentHeight / 2) {
        setIsVisible(false);
      } else if (scrolled <= componentHeight / 2) {
        setIsVisible(true);
      }
    };
    window.addEventListener("load", handleClick);
    window.addEventListener("scroll", handleClick);
    return () => {
      // Cleanup event listner
      document.removeEventListener("click", handleClick);
    };
  });
  useEffect(() => {
    function isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }
    if (isIOS()) {
      setBgText("bg-img-ios");
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      //startSlideShow();
    } else {
      // Stop slideshow
      //clearInterval(slideshowInterval);
    }

    return () => {
      clearInterval(bgImagesPath);
    };
  }, [isVisible]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows:false
  };
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-3 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className={
            "hero-bg parallax  bg-contain bg-center bg-no-repeat " + bgText
          }
          style={{
            backgroundImage: `url(${getJson("backgroundHeroImage")})`,
          }}
        ></div>

        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center text-yellow">
                <p className="text-7 fw-500  mb-2 mb-md-3">Welcome</p>
                <h2 className="text-16 fw-600 text-yellow mb-2 mb-md-3 typewriter_text">
                  {/* <Typewriter
                    options={{
                      strings: getJsonObject("typewriterText"),
                      autoStart: true,
                      loop: true,
                    }}
                  /> */}
                  <Slider {...settings}>
                    {getJsonObject("typewriterText").map((line, index) => (
                      <div key={index} className="sliding-text">
                        {line}
                      </div>
                    ))}
                  </Slider>
                </h2>
                <p className="text-5 text-light text-yellow mb-4">
                  based in Fremont, California.
                </p>
                <p id="dates" className="text-5 text-light text-yellow mb-4">
                  {" "}
                  <u>Upcoming Clinic:{} </u>{" "}
                  <HashLink
                    to={"/#schedule"}
                    className={"schedule-redirect text-yellow smooth-scroll"}
                  >
                    {futureClinicDates}
                  </HashLink>{" "}
                </p>
                <HashLink
                  to="/#contact"
                  className="btn contact-btn rounded-pill shadow-none smooth-scroll mt-2"
                >
                  Contact Us
                </HashLink>
              </div>
            </div>
          </div>
          <HashLink
            to="/#about"
            className="scroll-down-arrow text-white smooth-scroll"
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </HashLink>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Home);
