import "./App.scss";
import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Services from "./components/Services";
//import Resume from "./components/Resume";
import Team from "./components/Team";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useRef, useState } from "react";
import ClassicHeader from "./components/ClassicHeader";
import { commonConfig } from "./config/commonConfig";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouterProvider,
  Outlet,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Schedule from "./components/Schedule";
import CheckElementOnScreen from "./components/CheckElementOnScreen";
import { getFileUrl, getFirestoreData, getFirestoreUrls } from "./config/firebaseConfig";
import { getJson } from "./config/config";
import { createBrowserRouter } from "react-router-dom";
import ProjectDetailsModal from "./components/ProjectDetailsModal";
import Profile from "./components/Profile";
import "jquery";
import NotFound from "./components/NotFound";
import { Routing } from "./components/Routing";
import { HashLink } from "react-router-hash-link";
// require('dotenv').config()

function App() {
  const classicHeader = commonConfig.classicHeader;
  let isDefaultDark;
  if (localStorage.getItem("darkTheme") != null) {
    isDefaultDark = localStorage.getItem("darkTheme") == "true" ? true : false;
  } else {
    isDefaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [darkTheme, setDarkTheme] = useState(isDefaultDark);
  const [futureClinicDates, setFutureClinicDates] = useState([]);
  let compNo = 0;

  const updateCompNo = () => {
    compNo++;
    return compNo;
  };



  useEffect(() => {
    const fetchData = async () => {
      setisLoading(true); // Set loading to true before the fetch
      try {
        const response = await getFirestoreData(); // Assuming getFirestoreData returns a promise
        const responseUrl = await getFirestoreUrls(); // Assuming getFirestoreUrls returns a promise

        console.log("Firestore Fetch Was Successful");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setisLoading(false); // Set loading to false after fetch (success or error)
      }
    };

    fetchData();

    return () => {};
  }, []);

  useEffect(() => {
    console.log("Dark Theme: ", darkTheme);
    localStorage.setItem("darkTheme", darkTheme);
  }, [darkTheme]);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const router = createBrowserRouter([
    {
      index: true,
      element: (
        <>
          {" "}
          <div
            style={{ position: "relative" }}
            className={classicHeader ? "" : "side-header"}
          >
            {}

            <div id="main-wrapper">
              {classicHeader ? (
                <ClassicHeader
                  handleNavClick={handleNavClick}
                  darkTheme={darkTheme}
                  setDarkTheme={setDarkTheme}
                ></ClassicHeader>
              ) : (
                <Header handleNavClick={handleNavClick}></Header>
              )}
              <div id="content" role="main">
                <Home
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  handleNavClick={handleNavClick}
                  futureClinicDates={futureClinicDates}
                ></Home>
                <AboutUs
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                ></AboutUs>
                <Services
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                ></Services>
                <Team
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                ></Team>
                <Schedule
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                  setFutureClinicDates={setFutureClinicDates}
                  futureClinicDates={futureClinicDates}
                ></Schedule>
                <Testimonials
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                ></Testimonials>
                <Contact
                  classicHeader={classicHeader}
                  darkTheme={darkTheme}
                  compNo={updateCompNo()}
                ></Contact>
              </div>
            </div>
            {/* back to top */}
            <HashLink smooth to={"/#home"}>
            <Tooltip text="Back to Top" placement="top">
              <span
                id="back-to-top"
                className="rounded-circle"
                style={{ display: scrollTopVisible ? "inline" : "none" }}

              >
                <i className="fa fa-chevron-up"></i>
              </span>
            </Tooltip>
              </HashLink>
          </div>
        </>
      ),
    },
    {
      path: "team/:id",
      element: (
        <Profile
          darkTheme={darkTheme}
          data-toggle="modal"
          data-target="#exampleModal"
        />
      ),
    },
    {
      path: "*",
      element: <NotFound darkTheme={darkTheme} setDarkTheme={setDarkTheme} />,
    },
  ]);

  return !isLoading ? (
    <>
      <RouterProvider router={router} />
    </>
  ) : (
    <PreLoader></PreLoader>
  );
}

export default App;
