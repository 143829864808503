import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-scroll";
import { getJson } from "../config/config";
import ProjectDetailsModal from "./ProjectDetailsModal";
import "jquery/dist/jquery.min.js";
import { Modal } from "bootstrap";
import $ from "jquery";

function Profile({ darkTheme }) {
  let navigate = useNavigate();
  let { id } = useParams();
  const [teamPerson, setTeamPerson] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const team = useMemo(() => getJson("team"), []);
  const modalRef = useRef(null);
  let idName = "";
  let p = {};

  useEffect(() => {
    idName = id.replace(" ", "");
    if (id.includes("Dr.")) {
      idName = id.replace("Dr. ", "");
    }

    team.map((project) => {
      let projectName = project.name.replace(/\s/g, ""); // Remove all spaces from project name
      if (projectName.includes("Dr.")) {
        projectName = projectName.replace("Dr.", ""); // Remove "Dr." prefix if present
      }
      if (projectName.replace(" ", "").toLowerCase() === idName.toLowerCase()) {
        setTeamPerson(project);
      }
    });
  }, [id, team]);

  useEffect(() => {
    // Trigger button click when teamPerson is set
    if (teamPerson) {
      const btn = document.getElementById("urlModalBtn");
      btn.click();
    }
  }, [teamPerson]);

  return (
    <>
      {teamPerson && (
        <>
          <button
            id="urlModalBtn"
            href=""
            onClick={() => {
              setSelectedProjectDetails(teamPerson);
            }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="d-none" // Hide the button
          ></button>
          <div className="project-details-modal">
            {/* Modal */}
            <ProjectDetailsModal
              projectDetails={selectedProjectDetails}
              darkTheme={darkTheme}
            ></ProjectDetailsModal>
          </div>
        </>
      )}
    </>
  );
}

export default Profile;
