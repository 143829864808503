import React, { useEffect } from "react";
import { useState } from "react";
import { Tooltip } from "./Tooltip";
import { Link as ScrollLink } from "react-scroll";
import { getJson } from "../config/config";
import { getImage } from "../config/firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const ClassicHeader = ({ handleNavClick, darkTheme, setDarkTheme }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        navigate('/not-found');
      }
    }
  }, [hash]);

  const changeTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");
    if (header) {
      if (
        document.body.scrollTop > 180 ||
        document.documentElement.scrollTop > 180
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const handleNavLinkClick = (section) => {
    navigate(`#${section}`, { replace: true });
    setIsNavModalClose(true);
  };

  return (
    <header id="header" className="sticky-top-slide">
      <nav
        className={
          "primary-menu navbar navbar-expand-lg navbar-light bg-dark-gray border-bottom-0 sticky-top " +
          (stickyHeader ? "sticky-on" : "")
        }
      >
        <div className="container-fluid position-relative g-lg-4 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <ScrollLink
              smooth
              duration={500}
              style={{ cursor: "pointer", maxWidth: "100%", height: "auto" }}
              className="logo"
              to="home"
              title="GHMC"
              onClick={(e) => {
                handleNavLinkClick("home");
              }}
            >
              <span className="text-white fw-600 text-8 align-middle align-logo">
                <img
                  src={getImage(getJson("ghmcTextLogo"))}
                  alt="GHMC Logo"
                  className="align-middle"
                  style={{
                    width: "auto",
                    maxHeight: "60px",
                    height: "auto",
                  }}
                ></img>
              </span>
            </ScrollLink>
          </div>

          <div className="d-flex align-items-center order-lg-2">
            <a
              href="https://fremontgurdwara.org/"
              className="text-white fw-600 text-8 align-middle me-3"
            >
              <img
                src={getImage(getJson("fremontGurudwaraLogo"))}
                alt="Fremont Gurudwara Logo"
                className="align-middle"
                style={{
                  width: "auto",
                  maxHeight: "60px",
                  height: "auto",
                }}
              ></img>
            </a>

            <ul className="social-icons social-icons-dark mode d-flex align-items-center mb-0 me-3">
              <li className="switch-btn align-middle">
                <Tooltip
                  text={darkTheme ? "Dark Mode" : "Light Mode"}
                  placement="top"
                >
                  <a onClick={changeTheme} style={{ cursor: "pointer" }}>
                    <i
                      className={
                        darkTheme ? "fa-solid fa-moon" : "fa-solid fa-sun"
                      }
                    />
                  </a>
                </Tooltip>
              </li>
              {getJson("twitterUrl") && (
                <li className="social-icons-twitter social-tab me-2">
                  <Tooltip text="Twitter" placement="top">
                    <a
                      href={getJson("twitterUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {getJson("facebookUrl") && (
                <li className="social-icons-facebook me-2">
                  <Tooltip text="Facebook" placement="top">
                    <a
                      href={getJson("facebookUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </Tooltip>
                </li>
              )}
              {getJson("instagramUrl") && (
                <li className="social-icons-instagram">
                  <Tooltip text="Instagram" placement="top">
                    <a
                      href={getJson("instagramUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </Tooltip>
                </li>
              )}
            </ul>

            <button
              onClick={() => setIsNavModalClose(!isNavModalClose)}
              className={
                isNavModalClose
                  ? "navbar-toggler"
                  : "navbar-toggler show"
              }
              id="navbar-toggler"
              type="button"
            >
              <span />
              <span />
              <span />
            </button>
          </div>

          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse justify-content-center"
                : "show navbar-collapse justify-content-center"
            }
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="home"
                  onClick={() => handleNavLinkClick("home")}
                >
                  Home
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="about"
                  onClick={() => handleNavLinkClick("about")}
                >
                  About
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="services"
                  onClick={() => handleNavLinkClick("services")}
                >
                  Services
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="portfolio"
                  onClick={() => handleNavLinkClick("portfolio")}
                >
                  Team
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="schedule"
                  onClick={() => handleNavLinkClick("schedule")}
                >
                  Schedule
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="testimonial"
                  onClick={() => handleNavLinkClick("testimonial")}
                >
                  Testimonials
                </ScrollLink>
              </li>
              <li className="nav-item">
                <ScrollLink
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  spy
                  activeClass="active"
                  className="nav-link"
                  to="contact"
                  onClick={() => handleNavLinkClick("contact")}
                >
                  Contact
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default ClassicHeader;
