export function getJson(key) {
  const data = JSON.parse(sessionStorage.getItem("info"));
  let val = "undefined";
  try {
    val = data[key];
  } catch (error) {
    console.log(error);
  }
  return val;
}

export function getStringyJson(key) {
  const data = JSON.parse(sessionStorage.getItem("info"));
  return JSON.stringify(data[key]);
}

export function getJsonObject(key) {
  return JSON.parse(getStringyJson(key));
}
export function getStringyNestedJson(key, nestedKey) {
  const data = JSON.parse(sessionStorage.getItem("info"));
  data[key].map((result) => {
    return result[nestedKey];
  });
}

export function getLightBg(input) {
  let bg = null;
  if (input % 2 == 0) {
    bg = "bg-light";
  } else {
    bg = "";
  }
  return bg;
}

export function getDarkBg(input) {
  let bg = null;
  if (input % 2 == 0) {
    bg = "bg-dark-2";
  } else {
    bg = "bg-dark-1";
  }
  return bg;
}
