import { initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import firebase from "firebase/compat/app";
import { getJson } from "./config";
let downloadURL = "";
export const init = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
  };

  const app = initializeApp(firebaseConfig); 
  if (!navigator.onLine) {
    alert("Not Connected To Network");
  }
  return app;
};

export const getFirestoreData = async () => {
  const app = init();
  const db = getFirestore(app);
  const docRef = doc(db, "Data", "Info");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let data = docSnap.data();
    if (sessionStorage.getItem("info") !== data) {
      console.log("Document Updated in Browser ")
      sessionStorage.setItem("info", JSON.stringify(data));
      return true;
    }
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    alert("Server Error !");
  }
  return false;
};
export const getFirestoreUrls = async () => {
  const app = init();
  const db = getFirestore(app);
  const docRef = doc(db, "Data", "Urls");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let data = docSnap.data();
    if (sessionStorage.getItem("urls") !== data) {
      sessionStorage.setItem("urls", JSON.stringify(data));
      return true;
    }
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    alert("Server Error !");
  }
  return false;
};

export const getFileURL = async (path) => {
  const app = init();
  const storage = getStorage();
  const url = await getDownloadURL(ref(storage, path));

  return url;
};
export const getImage = (imagePath) => {
  if (getJson("firebaseStorage") === true) {
    if(imagePath!= undefined){
    const app = init();
    let modifiedPath = imagePath.replace(/[./_/-]/g, "");
    const dataString= (sessionStorage.getItem("urls"));
    const dataObj = JSON.parse(dataString)
    const obj = dataObj[modifiedPath]
    return (obj?.Url);
    }else{
      console.warn("Image Could Not Be Found From Firebase Storage!")
      return "";
    }
  }
};


