import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { getDarkBg, getJsonObject, getLightBg } from "../config/config";
import { getImage } from "../config/firebaseConfig";

const Team = ({ classicHeader, darkTheme, compNo }) => {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [previousProject, setPreviousProject] = useState();

  const filters = {
    DOCTORS: "Doctor",
    ADMINS: "Admin",
    LEADS: "Lead",
    VOLUNTEERS: "Student Volunteer",
  };

  const projectsData = getJsonObject("team");

  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    imagesLoaded(".portfolio-filter").on("progress", () => {
      isotope.current.layout();
    });

    // Delay the initial filter change to ensure layout is calculated
    setTimeout(() => {
      setFilterKey("Doctor");
    }, 100);

    return () => {
      isotope.current.destroy();
    };
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const pluralize = (word) => {
    if (word.endsWith("y")) {
      return word.slice(0, -1) + "ies";
    } else if (
      word.endsWith("s") ||
      word.endsWith("x") ||
      word.endsWith("z") ||
      word.endsWith("ch") ||
      word.endsWith("sh")
    ) {
      return word + "es";
    } else if (
      word.endsWith("o") ||
      word.endsWith("ss") ||
      word.endsWith("zz")
    ) {
      return word + "es";
    } else {
      return word + "s";
    }
  };

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getBriefInfo = (input) => {
    if (input["isStudent"] === true) {
      if (input["schoolType"] === "school") {
        return (
          <ul className="breif-list p-0 text-capitalize text-wrap">
            <li>{"High School " + input["grade"]}</li>
            <li>{"Interest: " + input["interests"]}</li>
          </ul>
        );
      } else if (input["schoolType"] === "college") {
        if (!input.hasOwnProperty("collegeName")) {
          return (
            <ul className="breif-list p-0 text-capitalize text-wrap">
              <li>{"College" + " " + input["grade"]}</li>
              {input.hasOwnProperty("major") && (
                <li>{"Major:" + " " + input["major"]}</li>
              )}
              <li>{"Interest: " + input["interests"]}</li>
            </ul>
          );
        }
        if (input["grade"] === "completed") {
          return (
            <ul className="breif-list p-0 text-capitalize text-wrap">
              <li>{input["collegeName"] + " " + "Graduate"}</li>
              {input.hasOwnProperty("major") && (
                <li>{"Major:" + " " + input["major"]}</li>
              )}
              <li>{"Interest: " + input["interests"]}</li>
            </ul>
          );
        }
        return (
          <ul className="breif-list p-0 text-capitalize text-wrap">
            <li>{input["grade"] + " " + "at" + " " + input["collegeName"]}</li>
            {input.hasOwnProperty("major") && (
              <li>{"Major:" + " " + input["major"]}</li>
            )}
            <li>{"Interest: " + input["interests"]}</li>
          </ul>
        );
      }
    } else if (input["categories"].includes("Doctor")) {
      return (
        <ul className="breif-list p-0 text-capitalize text-wrap">
          <li>{input["speciality"]}</li>
        </ul>
      );
    } else {
      return (
        <ul className="breif-list p-0 text-wrap">
          <li>{input["role"]}</li>
        </ul>
      );
    }
  };

  const formatUsername = (name) => {
    let username = name;
    username = username.replace(/ /g, "");
    if (username.includes("Dr.")) {
      username = username.replace("Dr.", "");
    }
    username = username.toLowerCase();
    return username;
  };

  const sortByName = (projects) => {
    return projects.sort((a, b) => a.name.localeCompare(b.name));
  };

  // Sort the entire projects data by name
  const sortedProjectsData = sortByName([...projectsData]);

  const groupedProjects = sortedProjectsData.reduce((acc, project) => {
    project.categories.forEach((category) => {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(project);
    });
    return acc;
  }, {});

  const displayCards = (filter) => {
    const orderedCategories = Object.values(filters);

    if (filter === "*") {
      return orderedCategories.map(
        (category, i) =>
          groupedProjects[category] && (
            <div key={i}>
              <h3
                className={
                  "text-center my-4 " +
                  (darkTheme ? "text-white" : "text-black")
                }
              >
                {pluralize(category)}
              </h3>
              <div className="row portfolio-filter filter-container g-4">
                {groupedProjects[category].map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div
                      className={
                        "card card-rounded h-100 " +
                        (darkTheme ? "bg-dark" : "bg-white")
                      }
                    >
                      <img
                        className="img-fluid d-block portfolio-image member-image"
                        src={getImage(project.thumbImage)}
                        alt=""
                      />
                      <a
                        className="btn-transparent team-btn card-rounded"
                        href={"/team/" + formatUsername(project.name)}
                      >
                        <div
                          className={
                            "card-body " + (darkTheme ? "text-white" : "")
                          }
                        >
                          <h3 className={darkTheme ? "text-white" : ""}>
                            {project["name"]}
                          </h3>
                          {getBriefInfo(project)}
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
      );
    } else {
      const filteredProjects = sortByName(
        sortedProjectsData.filter((project) =>
          project.categories.includes(filter)
        )
      );
      return (
        <div className="row portfolio-filter filter-container g-4">
          {filteredProjects.map((project, index) => (
            <div
              className={
                "col-sm-6 col-lg-4 filter-item " + project.categories.join(" ")
              }
              key={index}
            >
              <div
                className={
                  "card card-rounded h-100 " +
                  (darkTheme ? "bg-dark" : "bg-white")
                }
              >
                <img
                  className="img-fluid d-block portfolio-image member-image"
                  src={getImage(project.thumbImage)}
                  alt=""
                />
                <a
                  className="btn-transparent team-btn card-rounded"
                  href={"/team/" + formatUsername(project.name)}
                >
                  <div
                    className={"card-body " + (darkTheme ? "text-white" : "")}
                  >
                    <h3 className={darkTheme ? "text-white" : ""}>
                      {project["name"]}
                    </h3>
                    {getBriefInfo(project)}
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <section
        id="portfolio"
        className={
          "section " + (darkTheme ? getDarkBg(compNo) : getLightBg(compNo))
        }
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Our Team
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {pluralize(filters[oneKey])}
                </button>
              </li>
            ))}
            {/* TODO: Uncomment For Removing All Section */}
            {/*
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            */}
          </ul>
          <div className="portfolio popup-ajax-gallery container">
            {displayCards(filterKey)}
            <div className="join-team">
              <label id="join-lbl" className="text-6 text-primary join-comp">
                Want to join Our Team ?
              </label>
              <a
                href={getJsonObject("volunteerFrom")}
                id="join-btn"
                target="_blank"
                rel="noopener noreferrer"
                className="join-comp btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
              >
                Join
              </a>
            </div>
          </div>
        </div>
      </section>
      {selectedProjectDetails && (
        <ProjectDetailsModal
          project={selectedProjectDetails}
          darkTheme={darkTheme}
          onHide={setSelectedProjectDetails}
          previousProject={previousProject}
          setPreviousProject={setPreviousProject}
        />
      )}
    </>
  );
};

export default Team;
