import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImage } from "../config/firebaseConfig";

const ProjectDetailsModal = ({ darkTheme, projectDetails = {} }) => {
  const navigate = useNavigate();
  const [exitButtonStatus, setExitButtonStatus] = useState(false);
  const [btnText, setBtnText] = useState("");
  const [showCopiedText, setShowCopiedText] = useState(false);

  useEffect(() => {
    if (exitButtonStatus) {
      navigate("/#portfolio");
      setTimeout(() => {
        const element = document.getElementById("portfolio");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [exitButtonStatus, navigate]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" || event.key === "Esc") {
        setExitButtonStatus(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (projectDetails?.name) {
      document.title = `${projectDetails.name} - GHMC`;
    }

    return () => {
      document.title = "GHMC";
    };
  }, [projectDetails]);

  useEffect(() => {
    if (exitButtonStatus) {
      document.title = "GHMC";
    }
  }, [exitButtonStatus]);

  const handleCloseModal = () => {
    setExitButtonStatus(true);
  };

  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setBtnText("URL Copied!");
      setShowCopiedText(true);
      setTimeout(() => {
        setShowCopiedText(false);
      }, 2500);
    });
  };

  const getList = (input) => {
    if (!input) return null;

    const {
      isStudent,
      schoolType,
      grade,
      interests,
      collegeName,
      major,
      categories = [],
      speciality,
      role,
    } = input;

    if (isStudent) {
      if (schoolType === "school") {
        return (
          <>
            <li>{"Education:" + " High School " + grade}</li>
            <li>{"Interest: " + interests}</li>
          </>
        );
      } else if (schoolType === "college") {
        if (!collegeName) {
          return (
            <>
              <li>{"College " + grade}</li>
              {major && <li>{"Major: " + major}</li>}
              <li>{"Interest: " + interests}</li>
            </>
          );
        }
        if (grade === "completed") {
          return (
            <>
              <li>{collegeName + " Graduate"}</li>
              {major && <li>{"Major: " + major}</li>}
              <li>{"Interest: " + interests}</li>
            </>
          );
        }
        return (
          <>
            <li>{grade + " at " + collegeName}</li>
            {major && <li>{"Major: " + major}</li>}
            <li>{"Interest: " + interests}</li>
          </>
        );
      }
    } else if (categories.includes("Doctor")) {
      return <li>{"specialisation: " + speciality}</li>;
    } else {
      return <li>{"Role: " + role}</li>;
    }
  };

  return (
    <>
      <div
        className="modal fade bg-dark-1"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div
            className={
              "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
            }
          >
            <div className="modal-body">
              <button
                type="button"
                className={
                  "btn-close " +
                  (darkTheme ? "btn-close-white" : "") +
                  " " +
                  (exitButtonStatus ? "d-none" : "")
                }
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              />
              <button className="btn" onClick={copyToClipboard}>
                {darkTheme ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-regular fa-copy"></i>
                )}
              </button>
              {showCopiedText && (
                <span style={{ color: "green", marginLeft: "10px" }}>
                  {btnText}
                </span>
              )}

              <div
                className={
                  "container ajax-container " +
                  (darkTheme ? "bg-dark-2 text-light" : "")
                }
              >
                <h2
                  className={
                    "text-6 font-weight-600 text-center mb-4" +
                    (darkTheme ? " text-white" : "")
                  }
                >
                  {projectDetails?.name}
                </h2>

                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="float-start me-3 mb-3">
                      <img
                        className="img-fluid modal-images"
                        alt=""
                        src={getImage(projectDetails?.thumbImage)}
                        style={{ maxWidth: "300px", maxHeight: "300px" }}
                      />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: projectDetails?.projectInfo }}></p>
                  </div>
                  <div className="col-md-12">
                    <h4
                      className={
                        "text-4 font-weight-600 mt-4" +
                        (darkTheme ? " text-white" : "")
                      }
                    >
                      Individual Details:
                    </h4>
                    <ul
                      className={
                        "list-style-2 text-capitalize text-wrap " +
                        (darkTheme ? "list-style-light" : "")
                      }
                    >
                      {getList(projectDetails)}
                    </ul>
                    <div className="row no-gutters align-items-center">
                      {projectDetails?.socialLinks?.facebook === undefined &&
                      projectDetails?.socialLinks?.twitter === undefined &&
                      projectDetails?.socialLinks?.google === undefined &&
                      projectDetails?.socialLinks?.instagram === undefined &&
                      projectDetails?.socialLinks?.mail === undefined ? (
                        ""
                      ) : (
                        <>
                          <div
                            className={
                              "col-auto text-dark font-weight-600" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Share:
                          </div>
                          <div className="col-auto">
                            <ul
                              className={
                                "social-icons" +
                                (darkTheme ? " social-icons-muted" : "")
                              }
                            >
                              {projectDetails?.socialLinks?.facebook ===
                              undefined ? (
                                ""
                              ) : (
                                <li className="social-icons-facebook">
                                  <a
                                    data-toggle="tooltip"
                                    href={projectDetails?.socialLinks?.facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-original-title="Facebook"
                                  >
                                    <i className="fab fa-facebook-f" />
                                  </a>
                                </li>
                              )}
                              {projectDetails?.socialLinks?.twitter ===
                              undefined ? (
                                ""
                              ) : (
                                <li className="social-icons-twitter">
                                  <a
                                    data-toggle="tooltip"
                                    href={projectDetails?.socialLinks?.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-original-title="Twitter"
                                  >
                                    <i className="fab fa-twitter" />
                                  </a>
                                </li>
                              )}
                              {projectDetails?.socialLinks?.google ===
                              undefined ? (
                                ""
                              ) : (
                                <li className="social-icons-google">
                                  <a
                                    data-toggle="tooltip"
                                    href={projectDetails?.socialLinks?.google}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-original-title="Google"
                                  >
                                    <i className="fab fa-google" />
                                  </a>
                                </li>
                              )}
                              {projectDetails?.socialLinks?.instagram ===
                              undefined ? (
                                ""
                              ) : (
                                <li className="social-icons-instagram">
                                  <a
                                    data-toggle="tooltip"
                                    href={
                                      projectDetails?.socialLinks?.instagram
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-original-title="Instagram"
                                  >
                                    <i className="fab fa-instagram" />
                                  </a>
                                </li>
                              )}
                              {projectDetails?.socialLinks?.mail ===
                              undefined ? (
                                ""
                              ) : (
                                <li className="social-icons-email">
                                  <a
                                    data-toggle="tooltip"
                                    href={projectDetails?.socialLinks?.mail}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-original-title="Email"
                                  >
                                    <i className="fas fa-envelope" />
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailsModal;
