import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
// import { ToastContainer, toast } from "react-toastify";
import { getDarkBg, getJson, getLightBg } from "../config/config";

import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const formatPhoneNumber = (number) => {
  //Filter only numbers from the input
  let cleaned = ("" + number).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

const formatAddress = (string) => {
  const formatAddrs = string.replace(",", "\n");
  return formatAddrs;
};
const Contact = ({ classicHeader, darkTheme, compNo }) => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        "service_i86k3ms",
        "template_si6cin9",
        form.current,
        "c9HsDgGF0tvWyVnAL"
      )
      .then(
        (result) => {
          document.getElementById("contactFrom").reset();
          toast.success("Message sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          setSendingMail(false);
        },
        (error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };

  return (
    <section
      id="contact"
      className={
        "section " + (darkTheme ? getDarkBg(compNo) : getLightBg(compNo))
      }
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Contact
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Get in Touch
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start contact-card">
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Address
            </h2>
            <p
              className={
                "text-3 mb-2 formatted-address" +
                (darkTheme ? " text-light" : "")
              }
            >
              {formatAddress(getJson("address"))}
            </p>
            {/*Contact Details Not Needed Now */}

            {/* <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              <a href={"tel:+" + getJson("telNo")}>
                {formatPhoneNumber(getJson("mobNo"))}
              </a>
            </p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-fax" />
              </span>
              <a href={"tel:+" + getJson("telNo")}>
                {formatPhoneNumber(getJson("telNo"))}
              </a>
            </p>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              <a href={"mailto:" + getJson("email")}> {getJson("email")} </a>
            </p> */}

            <a
              href={getJson("contactFormLink")}
              className={
                "btn bg-primary rounded shadow my-2 " +
                (darkTheme ? "text-white" : "")
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </a>
            {getJson("twitterUrl") === "" &&
            getJson("facebookUrl") === "" &&
            getJson("instagramUrl") === "" ? (
              ""
            ) : (
              <>
                <h2
                  className={
                    "mb-3 text-5 text-uppercase " +
                    (darkTheme ? "text-white" : "")
                  }
                >
                  Follow Me
                </h2>
              </>
            )}

            <ul
              className={
                "social-icons justify-content-center justify-content-md-start " +
                (darkTheme ? "social-icons-muted" : "")
              }
            >
              {getJson("twitterUrl") === "" ? (
                ""
              ) : (
                <>
                  <li className="social-icons-twitter ">
                    <Tooltip text="Twitter" placement="top">
                      <a
                        href={getJson("twitterUrl")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </Tooltip>
                  </li>
                </>
              )}
              {getJson("facebookUrl") === "" ? (
                ""
              ) : (
                <>
                  <li className="social-icons-facebook">
                    <Tooltip text="Facebook" placement="top">
                      <a
                        href={getJson("facebookUrl")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </Tooltip>
                  </li>
                </>
              )}
              {getJson("instagramUrl") === "" ? (
                ""
              ) : (
                <>
                  <li className="social-icons-instagram">
                    <Tooltip text="Instagram" placement="top">
                      <a
                        href={getJson("instagramUrl")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </Tooltip>
                  </li>
                </>
              )}
            </ul>
          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1 maps-div">
            <iframe
              src={getJson("mapsSrc")}
              width="600"
              className={"mapsApi " + (darkTheme ? "dark-maps" : "")}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
